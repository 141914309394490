
import { defineComponent, shallowRef } from 'vue'
import PageContent from '@/components/PageContent.vue'
import LoadingBlock from '@/components/LoadingBlock.vue'
import HydraOperationButton from '@/components/HydraOperationButton.vue'
import SharedDimensionTags from '@/components/SharedDimensionTags.vue'
import { mapGetters, mapState } from 'vuex'
import LanguageSelect from '@/components/LanguageSelect.vue'
import TermWithLanguage from '@/components/TermWithLanguage.vue'
import { SharedDimension } from '@/store/types'
import { useHydraForm } from '@/use-hydra-form'
import { getRouteSearchParamsFromTemplatedOperation } from '@/router'
import { hydra } from '@tpluscode/rdf-ns-builders'

export default defineComponent({
  name: 'CubeProjectsView',
  components: {
    TermWithLanguage,
    PageContent,
    LoadingBlock,
    HydraOperationButton,
    SharedDimensionTags,
    LanguageSelect,
  },

  async mounted (): Promise<void> {
    await this.$store.dispatch('sharedDimensions/fetchEntrypoint')
    await this.$store.dispatch('sharedDimensions/fetchCollection')

    this.operation = this.collection.actions.get
    this.searchParams = this.collection.searchParams
    this.pageSize = this.searchParams.out(hydra.limit).value
    this.page = parseInt(this.$router.currentRoute.value.query.page as any) || 1
  },

  setup () {
    const operation = shallowRef()

    const form = useHydraForm(operation)

    return {
      ...form,
      page: 1,
      pageSize: 0,
      searchParams: shallowRef()
    }
  },

  computed: {
    ...mapState('app', [
      'selectedLanguage',
    ]),
    ...mapState('sharedDimensions', {
      collection: 'collection',
    }),
    ...mapGetters('sharedDimensions', {
      dimensions: 'dimensions',
    }),
  },

  async beforeRouteUpdate (to) {
    await this.$store.dispatch('sharedDimensions/fetchCollection', to.query)
    this.searchParams = this.collection.searchParams
    this.pageSize = this.searchParams.out(hydra.limit).value
    this.page = parseInt(to.query.page as any) || 1
  },

  methods: {
    selectLanguage (language: string): void {
      this.$store.dispatch('app/selectLanguage', language)
    },

    onSearch (e: CustomEvent) {
      if (this.operation && e.detail?.value) {
        this.page = 1
        const query = {
          ...getRouteSearchParamsFromTemplatedOperation(this.operation, e.detail?.value),
          page: 1
        }
        this.$router.push({ query })
      }
    },

    nextPage () {
      this.fetchPage(this.page + 1)
    },

    prevPage () {
      this.fetchPage(this.page - 1)
    },

    fetchPage (page: number) {
      if (this.operation) {
        this.page = page
        const query = {
          ...getRouteSearchParamsFromTemplatedOperation(this.operation, this.searchParams),
          page
        }

        this.$router.push({ query, })
      }
    },
  }
})
