"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commonActions = exports.getOperation = exports.findOperation = void 0;
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
function findOperation(resource, idOrType) {
    if (!resource.id.value.includes(window.APP_CONFIG.apiCoreBase)) {
        return null;
    }
    const matches = resource.operations.filter(op => op.pointer.has(rdf_ns_builders_1.rdf.type, idOrType).values.length);
    if (matches.length > 1) {
        console.error(`More than one match for operation ${idOrType} on ${resource.id}`);
    }
    return matches[0] || null;
}
exports.findOperation = findOperation;
function getOperation(resource, idOrType) {
    const operation = findOperation(resource, idOrType);
    if (!operation) {
        throw new Error(`Operation ${idOrType} not found on ${resource.id}`);
    }
    return operation;
}
exports.getOperation = getOperation;
const commonActions = (resource, additionalActions = {}) => ({
    get: findOperation(resource, rdf_ns_builders_1.schema.DownloadAction),
    create: findOperation(resource, rdf_ns_builders_1.schema.CreateAction),
    edit: findOperation(resource, rdf_ns_builders_1.schema.UpdateAction),
    replace: findOperation(resource, rdf_ns_builders_1.schema.ReplaceAction),
    delete: findOperation(resource, rdf_ns_builders_1.schema.DeleteAction),
    ...Object.entries(additionalActions).reduce((actions, [key, term]) => {
        return {
            ...actions,
            [key]: findOperation(resource, term),
        };
    }, {})
});
exports.commonActions = commonActions;
