"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const rdfine_1 = require("@tpluscode/rdfine");
const namespace_1 = require("@cube-creator/core/namespace");
const schema_1 = require("@rdfine/schema");
const strict_1 = require("@tpluscode/rdf-ns-builders/strict");
function mixin(base) {
    class Impl extends (0, schema_1.ThingMixin)(base) {
    }
    __decorate([
        (0, rdfine_1.property)({ path: strict_1.schema.name, values: 'array' })
    ], Impl.prototype, "names", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: strict_1.schema.alternateName, values: 'array' })
    ], Impl.prototype, "abbreviation", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.md.terms })
    ], Impl.prototype, "terms", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.md.export })
    ], Impl.prototype, "export", void 0);
    __decorate([
        rdfine_1.property.literal({ path: strict_1.schema.validThrough, type: Date })
    ], Impl.prototype, "validThrough", void 0);
    return Impl;
}
exports.default = mixin;
mixin.appliesTo = namespace_1.meta.SharedDimension;
