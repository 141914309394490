"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const filter_1 = require("clownface/filter");
const serializers_1 = require("@/store/serializers");
exports.default = (0, vue_1.defineComponent)({
    name: 'TermWithLanguage',
    props: {
        values: {
            type: Object,
            default: undefined,
        },
        selectedLanguage: {
            type: String,
            required: true,
        },
    },
    computed: {
        value() {
            var _a, _b, _c, _d;
            if (Array.isArray(this.values)) {
                let term = ((_a = this.values) === null || _a === void 0 ? void 0 : _a.find(({ language }) => language === this.selectedLanguage)) ||
                    ((_b = this.values) === null || _b === void 0 ? void 0 : _b.find(({ language }) => !language));
                if (term)
                    return term.value;
                for (const lang of serializers_1.displayLanguage) {
                    term = (_c = this.values) === null || _c === void 0 ? void 0 : _c.find(({ language }) => language === lang);
                    if (term)
                        return `${term.value} (${term.language})`;
                }
                return undefined;
            }
            const term = (_d = this.values) === null || _d === void 0 ? void 0 : _d.filter((0, filter_1.taggedLiteral)([
                this.selectedLanguage,
                ...serializers_1.displayLanguage,
                '*'
            ]));
            return term === null || term === void 0 ? void 0 : term.value;
        },
    },
});
